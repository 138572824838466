module.exports = {
  plugins: [
    "gatsby-plugin-top-layout",
    "gatsby-plugin-postcss",
    "gatsby-plugin-sass",
    {
      resolve: "gatsby-plugin-material-ui",
      options: {
        stylesProvider: {
          injectFirst: true,
        },
      },
    },
    "gatsby-plugin-styled-components",
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "markdown-pages",
        path: `${__dirname}/src/markdown-pages`,
      },
    },
    "gatsby-transformer-remark",
    {
      resolve: "gatsby-source-drupal",
      options: {
        baseUrl: "https://bpfd-stage-backend.parallelpublicworks.com",
        skipFileDownloads: true,
      },
    },
    {
      resolve: "gatsby-source-drupal-menu-links",
      options: {
        baseUrl: "https://bpfd-stage-backend.parallelpublicworks.com",
        menus: ["main", "secondary-navigation"], // Which menus to fetch, there are the menu IDs.
      },
    },
  ],
  siteMetadata: {
    title: "Ballpark Public Facilities District",
    drupalUrl: "https://bpfd-stage-backend.parallelpublicworks.com",
    adminEmail: "info@ballpark.org",
  },
};
